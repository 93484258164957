import React from "react"
import { Location } from "../commonTypes"
import FrontPage from "./FrontPage"

type IndexPageProps = {
  location: Location
}

function IndexPage(props: IndexPageProps) {
  return (
    <div>
      <FrontPage location={props.location} lang={`en`} />
    </div>
  )
}

export default IndexPage
